import React from 'react';
import "./Footer.css"

const Footer = () => {
    return (
        <div className="footer">
            <div className="footerContainer">
                <div>CHD-svetovanje.d.o.o.</div>
                <div>Javorjev Drevored 16</div>
                <div>1000 Ljubljana</div>
                {/* <div>lydia.droste@chd-consulting.com</div>
                <div>+386 70 503 123</div> */}
            </div>

            <div className="footerContainer">
                <div>Christof Droste</div>
                <div>christof.droste@chd-consulting.com</div>
                <div>+386 51 326 143</div>
            </div>
            <div className="footerContainer">
                <div><a target="_blank" href="https://si.linkedin.com/in/christof-droste-b8528515">linked in</a></div>
            </div>

        </div>
    )
}

export default Footer