import React from "react"
import { useState, useEffect } from "react"

import "./Resume.css"
const maxLen = 200

const ResumeTextPreview = props => {
    let text;
    if (props.preview && props.text.length > maxLen) {
        text = <div className="resumeText">
                <div className="resumeTextPreview">{ props.text }</div>
                <div>...</div>
            </div>
    } else if (props.text.length > maxLen) {
        text = <div className="resumeText resumeTextSlideDown">{ props.text }</div>
    } else {
        text = <div className="resumeText">{ props.text }</div>
    }


    return (
        <div onClick={ () => props.openOverlay() }>{ text }</div>
    )
}

const ResumeTableView = props => {
    const [rowIdx, setRowIdx] = useState(null)
    const [dims, setDims] = useState({h: window.innerHeight, w: window.innerWidth})

    useEffect( () => {
        const handleResize = () => {
            const w = window.innerWidth
            console.log("resize")
            if ((dims.w > 600 && w < 600) || (dims.w < 600 && w > 600)) {
                setDims({h: window.innerHeight, w: window.innerWidth})
            }
        }

        window.addEventListener("resize", handleResize)
        return _ => window.removeEventListener("resize", handleResize)
    })


    const openOverlay = idx => {
        setRowIdx(rowIdx === idx ? null : idx)
    }

    const isMobile = window.innerWidth <= 600
    const style={ gridTemplateColumns: isMobile ? null : "105px 180px auto auto" }
    const textStyle =  {"grid-column": "1/4", display: "flex"}

    return (
        <div className="resume" style={ style }>
            {
                props.resume.map( (item, i) => {

                    if (dims.w < 600) {
                        return (
                            [
                                <div onClick={ () => openOverlay(i) } className="resumeDate">{ item.date }</div>,
                                <div onClick={ () => openOverlay(i) } className="resumeTitle">{ item.title }</div>,
                                <div onClick={ () => openOverlay(i) } className="resumeDropdownSymbol" onClick={ () => openOverlay(i) }><b>﹀</b></div>,
                                <div style={ i === rowIdx ? textStyle : null } className="resumeTextMobile" onClick={ () => openOverlay(i) }>{ item.text }</div>,
                            ]
                        )
                    } else {
                        return (
                            [
                                <div className="resumeDate">{ item.date }</div>,
                                <div onClick={ () => openOverlay(i) } className="resumeTitle">{ item.title }</div>,
                                <ResumeTextPreview text={ item.text } preview={ i !== rowIdx } openOverlay={ () => openOverlay(i) }/>,

                                <div onClick={ () => openOverlay(i) } className="resumeDropdownSymbol"><b>{item.text.length > maxLen ? "﹀" : ""}</b></div>,
                            ]
                    )
                }
            })
            }
        </div>
    )
}


const Resume = () => {
    return (
        <div className="resumeContainer">
            <div className="heading"> Bio </div>
            <ResumeTableView resume={ resume } />
        </div>
    )
}


export default Resume;

const resume = [
    {
        title: "Coaching and adivisory activities",
        date: "2022-ongoing",
        text: `Advisory and coaching activities with several companies across multiple project areas including strategic development, marketing and sales, administration, R&D, supply chain, production, company organization, as well as personal and team coaching.`,
    },

    {
        title: "Senior Business Advisor at Solvesall d.o.o.",
        date: "2022-ongoing",
        text: `Strategic Development support at Solvesall d.o.o.`,
    },

    {
        title: "Spokesman of the advisory board at Selectrona GmbH",
        date: "2022-ongoing",
        text: `Advisory board member position at Selectrona GmbH, Germany.`,
    },

    {
        title: "CRO at Soehner Group GmbH",
        date: "2020-2021",
        text: `Restructuring and turnaround of all german operations. Noiseless adjustment of the structure and profitable turnaround of the company.`,
    },

    {
        title: "Interim CRO and Board Member at Hidria Group.",
        date: "2015-2020",
        text: `Interim CRO and Member of the Management Board at Hidria Group, a medium sized automotive supplier with 6 business units and 7 production sites in Slovenia, Germany, Hungary, and China.
        Implemented Organizational restructuring of Slovenian sites, as well as the Organizational and Financial Restructuring of a German subsidiary based on an IDW S6 report. Convinced all stakeholders (including owners, banks, credit insurances and workers council) of the feasibility and sustainability of the restructuring. Achieved noiseless adjustment of structure as well as the reduction of 1/3 of the total staff, which was based on a negotiated "Interessensausgleich/ Sozialplan" with IG-Metal and the workers council. Accomplished "exit out of intensive care" in close cooperation with all stakeholders in both countries one year ahead of schedule.  `
    },

    {
        title: "Interim Vice President of Production and Managing Director at Hella Changchun",
        date: "2014",
        text: `Interim Vice President of Production and Managing Director at Hella Lighting Changchun, China.
        Established the Changchun Location as the leading Chinese lighting plant. Built a team to successfully champion the start of three new lighting plants in Beijing, Chengdu and Jiaxing.
        `
    },

    {
        title: "Managing Director at Hella Saturnus Slovenia",
        date: "2008-2014",
        text: `Grew sales from €90 Mio to €250 Mio. Established location as Hella Group's fog-lamp competence center. Grew R&D capacity by 160 engineers.
        Board member at Hella India Lighting. Godfather and Technical supporter of Hella Chengdu and Jiaxing.
        `
    },

    {
        title: "Deputy Managing Director at Hella Saturnus Slovenia",
        date: "2007-2008",
        text: `Established new production technology (molding and painting of headlight lenses). Constructed a new facility and relocated technology from England to Slovenia. Completed the project after 6 months although the initial time allocation had been 9 months.`
    },
    
    {
        title: "Interim Managing Director of Hella Lighting China",
        date: "2006-2007",
        text: `Established an interim management team following the 100% takeover from the Chinese JV partner. Stabilized the location and filled key positions with new people. Successfully started 48 new projects despite insufficient production capacities.`
    },

    {
        title: "Technical Director at Hella Saturnus Slovenia",
        date: "2004-2006",
        text: `Increased productivity and performance as benchmarked to other locations, thereby regaining customers’ trust, and earning the right to establish a development and production location for headlamps and foglamps.` 
    },

    {
        title: "Head of Component Factory at Hella Lighting Germany",
        date: "2002-2003",
        text: `Reduced setup times by 30%, increased productivity by 15%, and established a highly automated production center for components of
        a newly developed, very complex headlamp.` 
    },

    {
        title: "Head of Production Technology at Hella Lighting Germany",
        date: "2001-2002",
        text: `In charge of fire remediation. 100 molding machines and 500 tools were affected, and consequently needed to be fully disassembled,
        cleaned and reassembled. Successfully renovated the entire production facility under full production load without causing a single line-stop upstream the supply chain.` 
    },

    {
        title: "Head of spare parts at Hella Aftermarket Germany",
        date: "1998-2000",
        text: `Integration of an English air conditioning spare parts trading company, following a merger with Hella Aftermarket services. Established project management and secured the supply chain
        for future growth.` 
    },

    {
        title: "Head of Coordination of foreign aftermarket factories at Hella.",
        date: "1994-1998",
        text: `Post-merger integration of a Danish refabrication company for starters and alternators.
        Building a new aftermarket lighting business unit for agricultural and construction machines. Merging a newly acquired development and sales company in Vienna with a traditional Hella production location in Burgenland, Austria.`
    },

    {
        title: "Head of second brand at Hella Aftermarket",
        date: "1991-1993",
        text: `Analysis of second brand program and development of future strategy. Transfer of profitable product lines into the main brand. 
        Liquidation of the second brand.`
    },

    {
        title: "Marketing, product coordination at Hella Aftermarket",
        date: "1987-1991",
        text: `Development and market launch of Hella Color Design Program. Development and market launch of car alarm line for Hella's second brand Optilux.`
    },
]
