import React from "react"
import "./Consulting.css"
import hella from "../../hella.png"
import soehner from "../../soehner.png"
import hidria from "../../hidria.png"
import { Layout, Section } from "../Layout"

const Why = () => {
    return (
        <div className="whyContainer">
            <div className="heading">What I can do for you</div>

            <div className="whyText">
                <h2>Strategy</h2>
                <ul>
                    <li>develop/adapt business strategy</li>
                    <li>find new business partners/suppliers/customers</li>
                </ul>
            </div>

            <div className="whyText">
                <h2>Interim Management (CEO/CRO/COO)</h2>
                <ul>
                    <li>Restructuring and Sustainable Stabilization</li>
                    <li>Crisis Management</li>
                </ul>
                {/* <p> */}
                {/* <div>Restructuring and Sustainable Stabilization</div> */}
                {/* <ul>
                    <li>Analyzing the current situation</li>
                    <li>Developing restructuring program</li>
                    <li>Supporting and leading the change process</li>
                </ul> */}
                {/* <div>Crisis Management.</div> */}
                {/* <ul>
                    <li>Analyzing the current situation with all stakeolders</li>
                    <li>giving proposals and advice for necessary actions</li>
                    <li>leading the communication process and follow up on all actions.</li>
                </ul> */}


                
                {/* </p> */}
            </div>

            <div className="whyText">
                <h2>Post Merger Integration</h2>
                <ul>
                    <li>negotiate with owners, banks, unions, and other stakeholders.</li>
                    <li>identify and enable operational and financial synergies.</li>
                </ul>
            </div>


            {/* <div className="whyText">
                <h2>Focus on the long term</h2>
                <p>
                    Consultants famously focus on the short term, often at the expense
                    of long term viability.
                    I believe in open communication and such and such.
                </p>
            </div>

            <div className="whyText">
                <h2>Coordinate the team</h2>
                <p>
                    Poor coordination of employees frequently creates inefficiencies
                    on the organizational level that then leads to bottlenecks. Such 
                    dynamics have a tendency to get worse over time and escalate.<br />

                    It is often easier for outsiders to identify and eradicate these 
                    problems, eliminating the root causes.
                </p>
            </div> */}
        </div>
    )
}


const Competencies = () => {
    return (
        <div className="competenciesContainer">
            <div className="heading">Competencies</div>
            
            <div className="competencies">
            <div>
                <h2>Industries</h2>
                <ul className="competenciesList">
                    <li>Automotive Tier1 and Tier2</li>
                    <li>Metal forming/welding</li>
                    <li>Metal stamping</li>
                    <li>Plastic molding/ painting</li>
                    <li>Aluminum die casting</li>
                    <li>Assembly</li>
                    <li>Automation technology</li>
                </ul>
            </div>

            <div>
                <h2>Expertise</h2>
                <ul className="competenciesList">
                    <li>Leadership</li>
                    <li>Communication/Moderation</li>
                    <li>Strategy Development</li>
                    <li>Process Development</li>
                    <li>Change Management</li>
                    <li>Operationional Excellence</li>
                    <li>Lean Manufacturing</li>
                </ul>
            </div>
            </div>

        </div>
    )
}

const References = () => {
    return (
        <div className="referencesContainer">
            <div className="heading">References</div>
            <div className="references">
                {/* <div>Hella</div>
                <div>Saturnus</div>
                <div>Hidrija</div>
                <div>Soehner Group</div> */}
                <div className="referencesImgContainer"><img src={ hella } alt="boo" /></div>
                <div className="referencesImgContainer"><img src={ hidria } alt="boo" /></div>
                <div className="referencesImgContainer"><img src={ soehner } alt="boo" /></div>
            </div>
        </div>
    )
}

const Consulting = (props) => {
    return (
        <Layout views={ props.views } currView={ props.currView } setCurrView={ props.setCurrView }>
            <Section><Why /></Section>
            <Section><Competencies/></Section>
            {/* <Section><References/></Section> */}
        </Layout>
    )
}

export default Consulting;