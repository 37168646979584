import React from "react"

import './Bio.css';
import { Layout, Section } from "../Layout"
import Resume from "./Resume/Resume"
import Profile from "./Profile/Profile"


// const isMobile = () => {
//   return window.innerWidth < 600
// }

const Awards = () => {
  return (
    <div>
      <div className="heading">Awards</div>
      <div className="awards">

        <div> <b>Manager of the year 2011 in Slovenia</b> </div>
        <div><b>Second best industrial company in Slovenia (Hella Saturnus)</b></div>
        <div><b>Best foreign direct investment in Slovenia (Hella Saturnus)</b></div>
        <div><b>Best rated company in Slovenia (Hella Saturnus)</b></div>
      </div>
    </div>
  )
}

const Bio = (props) => {
  return (
    <Layout views={ props.views } currView={ props.currView } setCurrView={ props.setCurrView }>
        <Section><Profile/> </Section>
        <Section><Resume/></Section>
        <Section><Awards/></Section>
        {/* <Section><div></div></Section> */}
    </Layout>
  )
}

export default Bio;
