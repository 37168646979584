import React from 'react';
import "./Layout.css"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"


const Section = (props) => {
    return (
        <div className="SectionOuter">
            <div className="SectionInner">
                { props.children }
            </div>
        </div>
    )
}


const Layout = (props) => {
    console.log(props)
    return (
        <div className="Layout">
        <Section><Header views={ props.views } currView={ props.currView } setCurrView = { props.setCurrView }/></Section>
            { props.children }
        <Section><Footer/></Section>
        </div>
    )
}

// export default Layout;
export { Layout, Section }