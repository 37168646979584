import React from 'react';
import { useState, useEffect } from "react";
import "./Header.css"

const mobileWidth = 1000;

const MenuOptions = (props) => {
    return (
        <div className={ props.className }>
            {
                props.views.map((view, i) => (
                    <div 
                        className={ props.currView === view ? "menuOptionSelected menuOption" : "menuOption"}
                        onClick={ () => {
                            props.setCurrView(view)
                        }}
                        key={i}
                    >{ view }</div>
                ))
            }
        </div>
    )
}

const MobileDropdown = (props) => {
    return (
        <MenuOptions className="mobileDropdown" views={ props.views } currView={ props.currView } setCurrView={ props.setCurrView }/>
    )
}

const MobileMenu = (props) => {
    const [isOpen, setToOpen] = useState(false)
    return (
        <div className="mobileMenu">
            <div onClick={() => setToOpen(!isOpen)}>≣</div>
            { isOpen ? <MobileDropdown views={ props.views } currView={ props.currView } setCurrView={ props.setCurrView }/> : null}
        </div>
    )
}

const DesktopMenu = (props) => {

    return (
        <MenuOptions className="desktopMenu" views={ props.views } currView={ props.currView } setCurrView={ props.setCurrView }/>
    )
}

const Header = (props) => {
    const [dims, setDims] = useState({h: window.innerHeight, w: window.innerWidth})

    useEffect( () => {
        const handleResize = () => {
            const w = window.innerWidth
            if ((dims.w > mobileWidth && w < mobileWidth) || (dims.w < mobileWidth && w > mobileWidth)) {
                setDims({h: window.innerHeight, w: window.innerWidth})
            }
        }

        window.addEventListener("resize", handleResize)
        return _ => window.removeEventListener("resize", handleResize)
    })


    return (
        <div className="Header">
            <div className="HeaderTitle" onClick={ () => props.setCurrView("bio")}>
                <div className="HeaderTitleName" >Christof Droste</div>
                <div className="HeaderTitleCompany" >CHD-CONSULTING</div>
            </div>
            <div>
                { 
                dims.w < mobileWidth
                ?  <MobileMenu views={ props.views } currView={ props.currView } setCurrView={ props.setCurrView }/> 
                : <DesktopMenu views={ props.views } currView={ props.currView } setCurrView={ props.setCurrView }/>
                }
            </div>
        </div>
    )
}

export default Header;