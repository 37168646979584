import React from "react"
import "./Training.css"
import { Layout, Section } from "../Layout"

const Training = () => {
    return (
        <div className="coachingContainer">
            <div className="heading">Training</div>
            <div className="coaching">
                <div className="areas">
                    <h2>Training Areas</h2>
                    <ul>
                        <li>Project Management</li>
                        <li>Lean Management</li>
                        <li>Quality</li>
                        <li>Process Development</li>
                        <li>Continuous Improvement Process</li>
                        <li>Communication</li>
                        <li>Operational Excellence</li>
                    </ul>
                </div>
                <div className="tailor">
                    <h2>Tailored Trainings</h2>
                    <p>
                        Knowledge development is a crucial aspect to maintaining a competitive advantage.
                        It is vital for managers and decision makers to constantly challenge existing views, 
                        and to explore new ones.
                        <br/>Tailored and focused trainings at chd-consulting are designed to leverage an organization's existing strengths, and to 
                        guide and enable the personal growth of individuals within the context of their organization.
                    </p>
                </div>

            </div>

        </div>
    )
}

const Coaching = () => {
    return (
        <div className="coachingContainer">
            <div className="heading">Coaching</div>
            <div className="coaching">
                <div className="areas">
                    <h2>Coaching Areas</h2>
                    <ul>
                        <li>Management</li>
                        <li>Communication</li>
                        <li>Leadership</li>
                        <li>Decision Making</li>
                        <li>Involvement</li>
                    </ul>
                </div>

                <div className="individual">
                    <h2>Individual Coaching</h2>
                    <p>
                        In today’s rapidly changing environment, people are an organization’s most valuable resource. They are the backbone of every company.<br/>
                        Individual coaching at chd-consulting gives executives and managers the knowledge and confidence to unlock their full potential. 
                    </p>
                </div>
            </div>

        </div>
    )
}

const TrainingAndCoaching = (props) => {
    return (
        <Layout views={ props.views } currView={ props.currView } setCurrView={ props.setCurrView }>
            <Section><Training/></Section>
            <Section><Coaching/></Section>
        </Layout>
    )
}

export default TrainingAndCoaching;