import React from "react"
import { useState } from "react"

import './App.css';
import  Bio from "./components/Bio/Bio"
import  Contact from "./components/Contact/Contact"
import  TrainingAndCoaching from "./components/Training/Training"
import  Consulting from "./components/Consulting/Consulting"

const App = () => {
  // const views = ["bio", "consulting", "training/coaching", "contact"]
  const views = ["bio", "consulting", "training/coaching"]
  const [currView, setCurrView] = useState("bio")
  console.log(currView)

  return (
    <div className="App">
      { 
        {
          "bio": <Bio views={views} currView={ currView } setCurrView={setCurrView}/>,
          // "contact": <Contact views={views} currView={ currView } setCurrView={setCurrView}/>,
          "training/coaching": <TrainingAndCoaching views={views} currView={ currView } setCurrView={setCurrView}/>,
          "consulting": <Consulting views={views} currView={ currView } setCurrView={setCurrView}/>
        }[currView]
      }
    </div>
  )
}
export default App;
